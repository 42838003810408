import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getNCCReport } from "./nccAPI";

const initialState = {
  status: "idle",
  error: undefined,
  listReport: undefined,
};

export const getNCCReportAsync = createAsyncThunk(
  "nccReport",
  async (params, thunkAPI) => {
    try {
      const response = await getNCCReport(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const nccSlice = createSlice({
  name: "ncc",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearNCCReport: (state) => {
      state.listReport = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getNCCReportAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNCCReportAsync.fulfilled, (state, action) => {
        state.listReport = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getNCCReportAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getNCCs",
        };
      });
  },
});

export const selectNCCReport = (state) => state.ncc.listReport;
export const nccsStatus = (state) => state.ncc.status;
export const nccsError = (state) => state.ncc.error;

export const { setIdleStatus, clearNCCReport } = nccSlice.actions;

export default nccSlice.reducer;
