import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  listMemberReportD40,
  listPointReportD40,
  listPackageReportD40,
  listProductReportD40,
} from "./reportD40API";

const initialState = {
  listMember: undefined,
  listPoint: undefined,
  listPackage: undefined,
  listProduct: undefined,
  status: "idle",
  error: undefined,
};

export const listMemberReportD40Async = createAsyncThunk(
  "listMemberReportD40",
  async (params, thunkAPI) => {
    try {
      const response = await listMemberReportD40(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const listPointReportD40Async = createAsyncThunk(
  "listPointReportD40",
  async (params, thunkAPI) => {
    try {
      const response = await listPointReportD40(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const listPackageReportD40Async = createAsyncThunk(
  "listPackageReportD40",
  async (params, thunkAPI) => {
    try {
      const response = await listPackageReportD40(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const listProductReportD40Async = createAsyncThunk(
  "listProductReportD40",
  async (params, thunkAPI) => {
    try {
      const response = await listProductReportD40(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const reportD40Slice = createSlice({
  name: "reportD40",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // ieventluding actions generated by createAsyeventThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(listMemberReportD40Async.pending, (state) => {
        state.status = "loading";
      })
      .addCase(listMemberReportD40Async.fulfilled, (state, action) => {
        state.listMember = action.payload.result;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(listMemberReportD40Async.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "listMemberReportD40",
        };
      })
      .addCase(listPointReportD40Async.pending, (state) => {
        state.status = "loading";
      })
      .addCase(listPointReportD40Async.fulfilled, (state, action) => {
        state.listPoint = action.payload.result;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(listPointReportD40Async.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "listPointReportD40",
        };
      })
      .addCase(listPackageReportD40Async.pending, (state) => {
        state.status = "loading";
      })
      .addCase(listPackageReportD40Async.fulfilled, (state, action) => {
        state.listPackage = action.payload.result;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(listPackageReportD40Async.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "listPackageReportD40",
        };
      })
      .addCase(listProductReportD40Async.pending, (state) => {
        state.status = "loading";
      })
      .addCase(listProductReportD40Async.fulfilled, (state, action) => {
        state.listProduct = action.payload.result;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(listProductReportD40Async.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "listProductReportD40",
        };
      });
  },
});

export const selectListMemberReport40 = (state) => state.reportD40.listMember;
export const selectListPointReport40 = (state) => state.reportD40.listPoint;
export const selectListPackageReport40 = (state) => state.reportD40.listPackage;
export const selectListProductReport40 = (state) => state.reportD40.listProduct;
export const reportD40Status = (state) => state.reportD40.status;
export const reportD40Error = (state) => state.reportD40.error;

export const { setIdleStatus } = reportD40Slice.actions;

export default reportD40Slice.reducer;
