import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getNutritionClubs } from "./nutritionClubAPI";

const initialState = {
  nutritionClubs: undefined,
  status: "idle",
  error: undefined,
};

export const getNutritionClubAsync = createAsyncThunk(
  "nutritionClub",
  async (params, thunkAPI) => {
    try {
      const response = await getNutritionClubs(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const nutritionClubSlice = createSlice({
  name: "nutritionClub",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearNutritionClub: (state) => {
      state.nutritionClubs = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNutritionClubAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNutritionClubAsync.fulfilled, (state, action) => {
        state.nutritionClubs = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getNutritionClubAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "nutritionClubs",
        };
      });
  },
});

export const selectNutritionClubs = (state) =>
  state.nutritionClub.nutritionClubs;
export const nutritionClubsStatus = (state) => state.nutritionClub.status;
export const nutritionClubsError = (state) => state.nutritionClub.error;

export const { clearNutritionClub, clearNutritionClubs, setIdleStatus } =
  nutritionClubSlice.actions;

export default nutritionClubSlice.reducer;
