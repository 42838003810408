import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getReportMemberOverView, getReportMembers } from "./memberReportAPI";

const initialState = {
  data: undefined,
  members: undefined,
  status: "idle",
  statusTable: "idle",
  error: undefined,
};

export const getReportMemberOverviewAsync = createAsyncThunk(
  "reportMemberOverview",
  async (params, thunkAPI) => {
    try {
      const response = await getReportMemberOverView(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getReportMembersAsync = createAsyncThunk(
  "reportMembers",
  async (params, thunkAPI) => {
    try {
      const response = await getReportMembers(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const reportMemberSlice = createSlice({
  name: "reportMember",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearReportMemberOverview: (state) => {
      state.data = undefined;
    },
    clearReportMembers: (state) => {
      state.members = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getReportMemberOverviewAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getReportMemberOverviewAsync.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getReportMemberOverviewAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getReportMemberOverview",
        };
      })
      .addCase(getReportMembersAsync.pending, (state) => {
        state.statusTable = "loading";
      })
      .addCase(getReportMembersAsync.fulfilled, (state, action) => {
        state.members = action.payload;
        state.statusTable = "idle";
        state.error = undefined;
      })
      .addCase(getReportMembersAsync.rejected, (state, action) => {
        state.statusTable = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getReportMembers",
        };
      });
  },
});

export const selectReportMemberOverview = (state) => state.reportMember.data;
export const selectReportMembers = (state) => state.reportMember.members;
export const reportMembersOverviewStatus = (state) => state.reportMember.status;
export const reportMembersStatus = (state) => state.reportMember.statusTable;
export const reportMembersOverviewError = (state) => state.reportMember.error;

export const { clearReportMemberOverview, clearReportMembers, setIdleStatus } =
  reportMemberSlice.actions;

export default reportMemberSlice.reducer;
