import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  reportVolumnPoint,
  reportUnit,
  reportPurchaser,
  reportUnitPerPurchaser,
  reportPenetration,
} from "./productSegAPI";

const initialState = {
  listVolumnPoint: undefined,
  listUnit: undefined,
  listPurchaser: undefined,
  listUnitPerPurchaser: undefined,
  listPenetration: undefined,
  statusVolumnPoint: "idle",
  statusUnit: "idle",
  statusPurchaser: "idle",
  statusUnitPerPurchaser: "idle",
  statusPenetration: "idle",
  status: "idle",
  error: undefined,
};

export const reportVolumnPointAsync = createAsyncThunk(
  "reportVolumnPoint",
  async (params, thunkAPI) => {
    try {
      const response = await reportVolumnPoint(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const reportUnitAsync = createAsyncThunk(
  "reportUnit",
  async (params, thunkAPI) => {
    try {
      const response = await reportUnit(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const reportPurchaserAsync = createAsyncThunk(
  "reportPurchaser",
  async (params, thunkAPI) => {
    try {
      const response = await reportPurchaser(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const reportUnitPerPurchaserAsync = createAsyncThunk(
  "reportUnitPerPurchaser",
  async (params, thunkAPI) => {
    try {
      const response = await reportUnitPerPurchaser(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const reportPenetrationAsync = createAsyncThunk(
  "reportPenetration",
  async (params, thunkAPI) => {
    try {
      const response = await reportPenetration(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const productSegSlice = createSlice({
  name: "productSeg",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // ieventluding actions generated by createAsyeventThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(reportVolumnPointAsync.pending, (state) => {
        state.statusVolumnPoint = "loading";
      })
      .addCase(reportVolumnPointAsync.fulfilled, (state, action) => {
        state.listVolumnPoint = action.payload.data;
        state.statusVolumnPoint = "idle";
        state.error = undefined;
      })
      .addCase(reportVolumnPointAsync.rejected, (state, action) => {
        state.statusVolumnPoint = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "reportVolumnPoint",
        };
      })
      .addCase(reportUnitAsync.pending, (state) => {
        state.statusUnit = "loading";
      })
      .addCase(reportUnitAsync.fulfilled, (state, action) => {
        state.listUnit = action.payload.data;
        state.statusUnit = "idle";
        state.error = undefined;
      })
      .addCase(reportUnitAsync.rejected, (state, action) => {
        state.statusUnit = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "reportUnit",
        };
      })
      .addCase(reportPurchaserAsync.pending, (state) => {
        state.statusPurchaser = "loading";
      })
      .addCase(reportPurchaserAsync.fulfilled, (state, action) => {
        state.listPurchaser = action.payload.data;
        state.statusPurchaser = "idle";
        state.error = undefined;
      })
      .addCase(reportPurchaserAsync.rejected, (state, action) => {
        state.statusPurchaser = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "reportPurchaser",
        };
      })
      .addCase(reportUnitPerPurchaserAsync.pending, (state) => {
        state.statusUnitPerPurchaser = "loading";
      })
      .addCase(reportUnitPerPurchaserAsync.fulfilled, (state, action) => {
        state.listUnitPerPurchaser = action.payload.data;
        state.statusUnitPerPurchaser = "idle";
        state.error = undefined;
      })
      .addCase(reportUnitPerPurchaserAsync.rejected, (state, action) => {
        state.statusUnitPerPurchaser = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "reportUnitPerPurchaser",
        };
      })
      .addCase(reportPenetrationAsync.pending, (state) => {
        state.statusPenetration = "loading";
      })
      .addCase(reportPenetrationAsync.fulfilled, (state, action) => {
        state.listPenetration = action.payload.data;
        state.statusPenetration = "idle";
        state.error = undefined;
      })
      .addCase(reportPenetrationAsync.rejected, (state, action) => {
        state.statusPenetration = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "reportPenetration",
        };
      });
  },
});

export const selectVolumnPoint = (state) => state.productSeg.listVolumnPoint;
export const selectUnit = (state) => state.productSeg.listUnit;
export const selectPurchaser = (state) => state.productSeg.listPurchaser;
export const selectUnitPerPurchaser = (state) =>
  state.productSeg.listUnitPerPurchaser;
export const selectPenetration = (state) => state.productSeg.listPenetration;
export const VolumnPointStatus = (state) => state.productSeg.statusVolumnPoint;
export const UnitStatus = (state) => state.productSeg.statusUnit;
export const PurchaserStatus = (state) => state.productSeg.statusPurchaser;
export const UnitPerPurchaserStatus = (state) =>
  state.productSeg.statusUnitPerPurchaser;
export const PenetrationStatus = (state) => state.productSeg.statusPenetration;
export const productSegStatus = (state) => state.productSeg.status;
export const productSegError = (state) => state.productSeg.error;

export const { setIdleStatus } = productSegSlice.actions;

export default productSegSlice.reducer;
