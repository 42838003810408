import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getAreas } from "./areaAPI";

const initialState = {
  list: undefined,
  status: "idle",
  error: undefined,
};

export const getAreasAsync = createAsyncThunk(
  "areas",
  async (params, thunkAPI) => {
    try {
      const response = await getAreas(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const areaSlice = createSlice({
  name: "area",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearAreas: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getAreasAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAreasAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getAreasAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getAreas",
        };
      });
  },
});

export const selectAreas = (state) => state.area.list;
export const areasStatus = (state) => state.area.status;
export const areasError = (state) => state.area.error;

export const { clearAreas, setIdleStatus } = areaSlice.actions;

export default areaSlice.reducer;
