import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getReportMemberOverView = (params, thunkAPI) => {
  return axiosClient.get("reporting/member-overview", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getReportMembers = (params, thunkAPI) => {
  return axiosClient.get("reporting/members", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const exportReportMembers = (params, options) => {
  return axiosClient.get("reporting/members/export", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const exportReportMemberOverView = (params, options) => {
  return axiosClient.get("reporting/members-overview", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
export {
  getReportMemberOverView,
  getReportMembers,
  exportReportMembers,
  exportReportMemberOverView,
};
