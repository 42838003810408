import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getReportMemberSponsors = (params, thunkAPI) => {
  return axiosClient.get("reporting/sponsors/members", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getReportSponsors = (params, thunkAPI) => {
  return axiosClient.get("reporting/sponsors", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getReportSponsorsChart = (params, thunkAPI) => {
  return axiosClient.get("reporting/sponsors/charts", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getReportSponsorsChartNewMember = (params, thunkAPI) => {
  return axiosClient.get("reporting/sponsors/charts/new-member", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
export {
  getReportMemberSponsors,
  getReportSponsors,
  getReportSponsorsChart,
  getReportSponsorsChartNewMember,
};
