import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  getReportMemberSponsors,
  getReportSponsors,
  getReportSponsorsChart,
  getReportSponsorsChartNewMember,
} from "./sponsorStatisticAPI";

const initialState = {
  data: undefined,
  membersSponsor: undefined,
  infoSponsor: undefined,
  infoSponsorChart: undefined,
  infoSponsorChartNewMember: undefined,
  status: "idle",
  statusChart: "idle",
  error: undefined,
};

export const getReportMemberSponsorAsync = createAsyncThunk(
  "reportMemberSponsor",
  async (params, thunkAPI) => {
    try {
      const response = await getReportMemberSponsors(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getReportSponsorAsync = createAsyncThunk(
  "reportSponsor",
  async (params, thunkAPI) => {
    try {
      const response = await getReportSponsors(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getReportSponsorChartAsync = createAsyncThunk(
  "reportSponsorChart",
  async (params, thunkAPI) => {
    try {
      const response = await getReportSponsorsChart(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getReportSponsorChartNewAsync = createAsyncThunk(
  "reportSponsorChartMember",
  async (params, thunkAPI) => {
    try {
      const response = await getReportSponsorsChartNewMember(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const reportMemberSlice = createSlice({
  name: "reportMemberSponsor",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearReportMemberSponsor: (state) => {
      state.membersSponsor = undefined;
    },
    clearReportSponsor: (state) => {
      state.infoSponsor = undefined;
    },
    clearInfoSponsorChartNewMember: (state) => {
      state.infoSponsorChartNewMember = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getReportMemberSponsorAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getReportMemberSponsorAsync.fulfilled, (state, action) => {
        state.membersSponsor = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getReportMemberSponsorAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getReportMemberSponsor",
        };
      })
      .addCase(getReportSponsorAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getReportSponsorAsync.fulfilled, (state, action) => {
        state.infoSponsor = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getReportSponsorAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getReportSponsor",
        };
      })
      .addCase(getReportSponsorChartAsync.pending, (state) => {
        state.statusChart = "loading";
      })
      .addCase(getReportSponsorChartAsync.fulfilled, (state, action) => {
        state.infoSponsorChart = action.payload;
        state.statusChart = "idle";
        state.error = undefined;
      })
      .addCase(getReportSponsorChartAsync.rejected, (state, action) => {
        state.statusChart = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getReportSponsorChart",
        };
      })
      .addCase(getReportSponsorChartNewAsync.pending, (state) => {
        state.statusChart = "loading";
      })
      .addCase(getReportSponsorChartNewAsync.fulfilled, (state, action) => {
        state.infoSponsorChartNewMember = action.payload;
        state.statusChart = "idle";
        state.error = undefined;
      })
      .addCase(getReportSponsorChartNewAsync.rejected, (state, action) => {
        state.statusChart = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getReportSponsorNewMember",
        };
      });
  },
});

export const selectReportMemberSponsor = (state) =>
  state.reportMemberSponsor.membersSponsor;
export const selectReportSponsor = (state) =>
  state.reportMemberSponsor.infoSponsor;
export const selectReportSponsorChart = (state) =>
  state.reportMemberSponsor.infoSponsorChart;
export const selectReportSponsorChartNewMember = (state) =>
  state.reportMemberSponsor.infoSponsorChartNewMember;
export const selectMembersStatus = (state) => state.reportMemberSponsor.status;
export const selectChartStatus = (state) =>
  state.reportMemberSponsor.statusChart;
export const selectMembersSponsorError = (state) =>
  state.reportMemberSponsor.error;

export const {
  clearReportSponsor,
  clearReportMembers,
  setIdleStatus,
  clearInfoSponsorChartNewMember,
} = reportMemberSlice.actions;

export default reportMemberSlice.reducer;
