import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const listMemberReportD40 = (params, thunkAPI) => {
  return axiosClient.get("report-d-forty/members", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const listPointReportD40 = (params, thunkAPI) => {
  return axiosClient.get("report-d-forty/points", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const listPackageReportD40 = (params, thunkAPI) => {
  return axiosClient.get("report-d-forty/packages", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const listProductReportD40 = (params, thunkAPI) => {
  return axiosClient.get("report-d-forty/products", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  listMemberReportD40,
  listPointReportD40,
  listPackageReportD40,
  listProductReportD40,
};
