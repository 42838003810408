import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  getProductGroups,
  getProducts,
  getProductCategory,
} from "./productAPI";

const initialState = {
  list: undefined,
  topList: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
  group: [],
  category: [],
};

export const getProductsAsync = createAsyncThunk(
  "products",
  async (params, thunkAPI) => {
    try {
      const response = await getProducts(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getProductCategoryAsync = createAsyncThunk(
  "products-category",
  async (params, thunkAPI) => {
    try {
      const response = await getProductCategory(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getProductGroupsAsync = createAsyncThunk(
  "products-group",
  async (params, thunkAPI) => {
    try {
      const response = await getProductGroups(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearProducts: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getProductsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProductsAsync.fulfilled, (state, action) => {
        state.list = action.payload.result;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getProductsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "products",
        };
      })
      .addCase(getProductGroupsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProductGroupsAsync.fulfilled, (state, action) => {
        state.group = action.payload.result.data;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getProductGroupsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "products-group",
        };
      })
      .addCase(getProductCategoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProductCategoryAsync.fulfilled, (state, action) => {
        state.category = action.payload?.result?.categoryItemDtos;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getProductCategoryAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "products-category",
        };
      });
  },
});

export const selectProducts = (state) => state.products.list;
export const productsError = (state) => state.products.error;
export const selectProductGroups = (state) => state.products.group;
export const selectProductCategory = (state) => state.products.category;
export const productsStatus = (state) => state.products.status;

export const { clearProducts, setIdleStatus } = productSlice.actions;

export default productSlice.reducer;
