import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getPackages } from "./packageAPI";

const initialState = {
  list: {
    data: [],
    total: 0,
  },
  listCustom: {
    data: [],
    total: 0,
  },
  listOffice: {
    data: [],
    total: 0,
  },
  total: 0,
  detail: {},
  status: "idle",
  error: undefined,
};

export const getPackagesAsync = createAsyncThunk(
  "packages",
  async (params, thunkAPI) => {
    try {
      const response = await getPackages(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      console.log(error);
      // return thunkAPI.rejectWithValue(error.response.data)
    }
  }
);

export const getOfficePackagesAsync = createAsyncThunk(
  "packages-office",
  async (params, thunkAPI) => {
    try {
      const response = await getPackages(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      console.log(error);
      // return thunkAPI.rejectWithValue(error.response.data)
    }
  }
);

export const getCustomPackagesAsync = createAsyncThunk(
  "packages-custom",
  async (params, thunkAPI) => {
    try {
      const response = await getPackages(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      console.log(error);
      // return thunkAPI.rejectWithValue(error.response.data)
    }
  }
);

export const packageSlice = createSlice({
  name: "packages",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearPackages: (state) => {
      state.list = initialState.list;
    },
    clearOfficePackages: (state) => {
      state.list = initialState.listOffice;
    },
    clearCustomPackages: (state) => {
      state.list = initialState.listCustom;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getPackagesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPackagesAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getPackagesAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getPackages",
        };
      })
      .addCase(getCustomPackagesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCustomPackagesAsync.fulfilled, (state, action) => {
        state.listCustom = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getCustomPackagesAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "packages-custom",
        };
      })
      .addCase(getOfficePackagesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOfficePackagesAsync.fulfilled, (state, action) => {
        state.listOffice = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getOfficePackagesAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "packages-office",
        };
      });
  },
});

export const selectPackages = (state) => state.packages.list;
export const selectCustomPackages = (state) => state.packages.listCustom;
export const selectOfficePackages = (state) => state.packages.listOffice;
export const packagesError = (state) => state.packages.error;
export const packagesStatus = (state) => state.packages.status;

export const {
  clearPackages,
  setIdleStatus,
  clearCustomPackages,
  clearOfficePackages,
} = packageSlice.actions;

export default packageSlice.reducer;
