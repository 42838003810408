import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/Auth/authSlice";
import nccReducer from "../features/ncc/nccSlice";
import areaReducer from "../features/area/areaSlice";
import provinceReducer from "../features/province/provinceSlice";
import packageReducer from "../features/packages/packageSlice";
import productSegReducer from "../features/productSeg/productSegSlice";
import productReducer from "../features/product/productSlice";
import reportMemberReducer from "../features/memberReport/memberReportSlice";
import reportMemberSponsorReducer from "../features/sponsorStatistic/sponsorStatisticSlice";
import nutritionClub from "../features/nutritionClub/nutritionClubSlice";
import reportD40Reducer from "../features/reportD40/ReportD40Slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    products: productReducer,
    area: areaReducer,
    province: provinceReducer,
    ncc: nccReducer,
    packages: packageReducer,
    productSeg: productSegReducer,
    reportMember: reportMemberReducer,
    reportMemberSponsor: reportMemberSponsorReducer,
    nutritionClub: nutritionClub,
    reportD40: reportD40Reducer,
  },
});
