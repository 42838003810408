import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getProvinces } from "./provinceAPI";

const initialState = {
  list: undefined,
  status: "idle",
  error: undefined,
};

export const getProvincesAsync = createAsyncThunk(
  "provinces",
  async (params, thunkAPI) => {
    try {
      const response = await getProvinces(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const provinceSlice = createSlice({
  name: "province",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearProvinces: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getProvincesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProvincesAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getProvincesAsync.rejected, (state, action) => {
        state.status = "idle";
        if (
          action?.error?.name !== "AbortError" &&
          action?.payload?.statusCode !== 401 &&
          action?.payload?.statusCode !== 403
        ) {
          message.error("Lấy dữ liệu thất bại!");
        }
        state.error = {
          payload: action.payload,
          title: "getProvinces",
        };
      });
  },
});

export const selectProvinces = (state) => state.province.list;
export const provincesStatus = (state) => state.province.status;
export const provincesError = (state) => state.province.error;

export const { clearProvinces, setIdleStatus } = provinceSlice.actions;

export default provinceSlice.reducer;
