import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getProducts = (params, thunkAPI) => {
  return axiosClient.get("permission/product", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getProductGroups = (params, thunkAPI) => {
  return axiosClient.get("permission/product-group", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getProductCategory = (params, thunkAPI) => {
  return axiosClient.get("permission/tag/productCategory", {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { getProducts, getProductGroups, getProductCategory };
